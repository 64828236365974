import React from "react"
import Layout from "../../components/layout"
import NewClient from "../../components/atolo/newClient"
import AtoloLoginGuard from "../../components/atolo/AtoloLoginGuard"
import { Helmet } from "react-helmet"

const CreateClientPage = () => {
  return (
    <AtoloLoginGuard>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Atolo - Eval | Create client</title>
      </Helmet>
      <Layout type="atolo" Ò>
        <div className="flex justify-center flex-1 h-full w-full max-w-4xl w-4/5 md:h-auto md:pt-8 md:pb-20">
          <NewClient />
        </div>
      </Layout>
    </AtoloLoginGuard>
  )
}

export default CreateClientPage
