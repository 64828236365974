import validate from "validate.js"

export const getErrors = state => {
  return {
    companyname: validateName(state.companyname),
    maxevaluations: validatePositiveNumber(state.maxevaluations),
    maxEasyEvaluations: validateNumber(state.maxEasyEvaluations),
    numberofpersons: validatePositiveNumber(state.numberofpersons),
    contactpersons: state.contactpersons.map(contactPerson => isValidPerson(contactPerson)),
    theirref1: false,
  }
}

export const hasErrors = errors => {
  return (
    errors.companyname ||
    errors.maxevaluations ||
    errors.contactpersons.map(person => Object.values(person).filter(e => !!e)).filter(e => e.length > 0).length > 0
  )
}

const isValidPerson = person => ({
  firstname: validateName(person.firstname),
  lastname: validateName(person.lastname),
  email: isValidEmail(person.email),
  commlangcode: !isValidLanguage(person.commlangcode),
})

const validateName = name => {
  const errors = []
  if (validate.isEmpty(name)) {
    errors.push("nameRequiredError")
  }
  if (!validate.isString(name)) {
    errors.push("nameShouldBeTextError")
  }
  if (errors.length > 0) {
    return errors
  }
  return false
}

const validatePositiveNumber = number => {
  const errors = []
  if (validate.isEmpty(number)) {
    errors.push("numberRequiredError")
  }
  if (number < 0) {
    errors.push("numberShouldBePositiveError")
  }
  if (!isNumeric(number)) {
    errors.push("numberShouldBeNumberError")
  }
  if (errors.length > 0) {
    return errors
  }
  return false
}

const validateNumber = number => {
  const errors = []
  if (validate.isEmpty(number)) {
    errors.push("numberRequiredError")
  }
  if (number < 0) {
    errors.push("numberShouldBePositiveError")
  }
  if (!isNumeric(number)) {
    errors.push("numberShouldBeNumberError")
  }
  return false
}

function isNumeric(n) {
  return !isNaN(parseFloat(n)) && isFinite(n)
}

//This method is not working, should be tested
const isValidEmail = email => {
  const res = validate({ email }, { email: { email: true } })
  if (res) {
    return ["emailInvalidError"]
  } else {
    return false
  }
}

const allowedLanguageCodes = ["fr", "en", "de", "nl"]

const isValidLanguage = language => allowedLanguageCodes.includes(language)
