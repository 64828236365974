import React from "react"
import TextInput from "../../../base/forms/textInput"
import Select from "../../../base/forms/select"
import { dropdownLanguageOptions } from "../../../../utils/language"
import { defineMessages } from "gatsby-plugin-intl"
import useFormatMessage from "../../../../utils/hooks/useFormatMessage"

const messages = defineMessages({
  createClientHrManagerFirstNameInputPlaceholder: "First Name",
  createClientHrManagerLastNameInputPlaceholder: "Last Name",
  createClientHrManagerEmailInputPlaceholder: "Email",
  createClientHrManagerFunctionInputPlaceholder: "Function",
})
const NewPerson = ({ setPerson, person, errors, showErrors }) => {
  const handleChange = e => {
    setPerson({ ...person, [e.target.name]: e.target.value })
  }
  const formatMessage = useFormatMessage()
  return (
    <div className="flex flex-row align-center flex-wrap">
      <TextInput
        placeholder={formatMessage(messages.createClientHrManagerFirstNameInputPlaceholder)}
        name="firstname"
        value={person.firstname}
        onChange={handleChange}
        required
        invalid={showErrors && errors.firstname}
        errors={errors.firstname}
        className="mr-2"
      />
      <TextInput
        placeholder={formatMessage(messages.createClientHrManagerLastNameInputPlaceholder)}
        name="lastname"
        value={person.lastname}
        onChange={handleChange}
        required
        invalid={showErrors && errors.lastname}
        errors={errors.lastname}
        className="mr-2"
      />
      <TextInput
        placeholder={formatMessage(messages.createClientHrManagerEmailInputPlaceholder)}
        name="email"
        value={person.email}
        onChange={handleChange}
        required
        invalid={showErrors && errors.email}
        errors={errors.email}
        className="mr-2"
      />
      <TextInput
        placeholder={formatMessage(messages.createClientHrManagerFunctionInputPlaceholder)}
        name="theirref2"
        value={person.theirref2}
        onChange={handleChange}
        required
        invalid={showErrors && errors.theirref2}
        errors={errors.theirref2}
        className="mr-2"
      />

      <Select
        value={person.commlangcode}
        name="commlangcode"
        onChange={commlangcode => setPerson({ ...person, commlangcode })}
        options={dropdownLanguageOptions}
        required
        invalid={showErrors && errors.commlangcode}
      />
    </div>
  )
}

export default NewPerson
