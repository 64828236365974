import React, { useState } from "react"
import { v4 as uuid } from "uuid"
import useUser from "../../../utils/hooks/useUser"
import GreenLine from "../../layout/greenLine"
import Title from "../../layout/title"
import Subtitle from "../../layout/subtitle"
import TextInput from "../../base/forms/textInput"
import Label from "../../base/forms/label"
import NewPerson from "./components/newPerson"
import { getErrors, hasErrors } from "./utils/validations"
import Button from "../../base/buttons/regular"
import useCreateCompany from "../../../utils/hooks/useCreateCompany"
import useCreatePerson from "../../../utils/hooks/useCreatePerson"
import { forEach } from "../../../utils/async/asyncForEach"
import usePasswordResetRequest from "../../../utils/hooks/usePasswordResetRequest"
import notifications from "../../../utils/notifications"
import { defineMessages } from "gatsby-plugin-intl"
import _ from "lodash"
import useFormatMessage from "../../../utils/hooks/useFormatMessage"
import { EvaluationType } from "src/api/types"

const messages = defineMessages({
  nameRequiredError: "Required",
  nameShouldBeTextError: "Name should be text",
  numberRequiredError: "Required",
  numberShouldBePositiveNumber: "Number should be positive",
  numberShouldBeNumberError: "Number should be number",
  emailInvalidError: "Email invalid",
  createClientSuccessNotificationTitle: "Success!",
  createClientSuccessNotificationMessage: "The client is successfully created.",
  createClientErrorNotificationTitle: "Oops! Something went wrong.",
  createClientTitle: "New Client",
  createClientCompanyInformationSubTitle: "Company information",
  createClientCompanyNameInputLabel: "Company name: ",
  createClientCompanyMaxEvaluationsInputLabel: "Max evaluations:",
  createClientCompanyMaxEasyEvaluationsInputLabel: "Max easy evaluations:",
  createClientCompanyNumberOfPersonsInputLabel: "Number of persons:",
  createClientContactPersonSubtitle: "Contact person",
  createClientAddMoreUsersButton: "Add more users",
  createClientSubmitButton: "Create Client",
  createClientCompanyNameInputPlaceHolder: "Company",
  createClientCompanyNumberOfPersonsInputPlaceHolder: "# Persons",
  createClientCompanyMaxEvaluationsInputPlaceHolder: "Max # evaluations",
  createClientCompanyMaxEasyEvaluationsInputPlaceHolder: "Max # of easy evaluations",
})

const initialState = {
  companyname: "",
  maxevaluations: null,
  maxEasyEvaluations: null,
  numberofpersons: null,
  contactpersons: [{ firstname: "", lastname: "", email: "", theirref2: "", commlangcode: "fr" }],
}

const NewClient = () => {
  const [client, setClient] = useState(initialState)
  const [showErrors, setShowErrors] = useState(false)

  const { createCompany, loading: CLoading } = useCreateCompany()
  const { createPerson, loading: PLoading } = useCreatePerson()
  const { requestResetPassword } = usePasswordResetRequest()
  const user = useUser()
  const formatMessage = useFormatMessage()

  const errorCodes = getErrors(client)

  // Format error codes to messages (can't be extracted from component as it uses useFormatMessage hook (unless we provide formatMessage as parameter to util))
  const errors = {}
  _.forOwn(errorCodes, (clientAttrs, attr) => {
    if (attr === "contactpersons") {
      errors[attr] = clientAttrs.map(contactPerson => {
        const errorsContatcperson = {}
        _.forOwn(contactPerson, (attrErrors, key) => {
          let formattedError
          if (attrErrors && attrErrors.length > 0) {
            formattedError = attrErrors.map(error => {
              return formatMessage(messages[error])
            })
          }
          errorsContatcperson[key] = formattedError
        })
        return errorsContatcperson
      })
    } else {
      let formattedError
      if (clientAttrs && clientAttrs.length > 0) {
        formattedError = clientAttrs.map(error => {
          return formatMessage(messages[error])
        })
      }
      errors[attr] = formattedError
    }
  })
  const addNewPersonForm = () => {
    const newArrayOfForms = [...client.contactpersons]

    newArrayOfForms.push({
      firstname: "",
      lastname: "",
      email: "",
      theirref2: "",
      commlangcode: "fr",
    })

    setClient({
      ...client,
      contactpersons: newArrayOfForms,
    })
  }

  const handleSubmit = async () => {
    if (hasErrors(errors)) {
      setShowErrors(true)
    } else {
      try {
        const companyUUID = uuid()

        await createCompany({
          uuid: companyUUID,
          companyname: client.companyname,
          maxevaluationtypes: {
            [EvaluationType.STANDARD]: client.maxevaluations,
            [EvaluationType.EASY]: client.maxEasyEvaluations,
          },
          numberofpersons: client.numberofpersons,
          selleruuid: user.user.uuid,
        })

        await forEach(client.contactpersons, async contactperson => {
          const personUUID = uuid()

          const person = await createPerson(
            {
              uuid: personUUID,
              firstname: contactperson.firstname,
              lastname: contactperson.lastname,
              commlangcode: contactperson.commlangcode.toLowerCase(),
              commonname: `${contactperson.firstname} ${contactperson.lastname}`,
              email: contactperson.email,
              companyuuid: companyUUID,
              isevaluatedperson: false,
              ishrmanager: true,
              isetolo: false,
              theirref2: contactperson.theirref2,
            },
            companyUUID
          )

          await requestResetPassword(person.email)
        })
        setClient(initialState)
        setShowErrors(false)
        notifications.success(
          formatMessage(messages.createClientSuccessNotificationTitle),
          formatMessage(messages.createClientSuccessNotificationMessage)
        )
      } catch (err) {
        notifications.error(formatMessage(messages.createClientErrorNotificationTitle), err.message, err.details)
      }
    }
  }

  return (
    <div className="container flex flex-col">
      <div className="w-full bg-white shadow sm:rounded">
        <GreenLine />
        <div className="p-8 sm:px-8">
          <Title>{formatMessage(messages.createClientTitle)}</Title>
          <Subtitle>{formatMessage(messages.createClientCompanyInformationSubTitle)}</Subtitle>
          <div className="w-3/4">
            <div className="flex flex-row items-center">
              <Label className="mt-4 md:flex" name={formatMessage(messages.createClientCompanyNameInputLabel)}>
                <TextInput
                  placeholder={formatMessage(messages.createClientCompanyNameInputPlaceHolder)}
                  value={client.companyname}
                  onChange={e => {
                    setClient({
                      ...client,
                      companyname: e.target.value,
                    })
                  }}
                  required
                  invalid={showErrors && errors.companyname}
                  errors={errors.companyname}
                />
              </Label>
            </div>
            <div className="flex flex-row items-center">
              <Label className="mt-4 md:flex" name={formatMessage(messages.createClientCompanyMaxEvaluationsInputLabel)}>
                <TextInput
                  type="number"
                  placeholder={formatMessage(messages.createClientCompanyMaxEvaluationsInputPlaceHolder)}
                  value={client.maxevaluations}
                  onChange={e => {
                    setClient({
                      ...client,
                      maxevaluations: e.target.value,
                    })
                  }}
                  required
                  invalid={showErrors && errors.maxevaluations}
                  errors={errors.maxevaluations}
                />
              </Label>
            </div>
            <div className="flex flex-row items-center">
              <Label className="mt-4 md:flex" name={formatMessage(messages.createClientCompanyMaxEasyEvaluationsInputLabel)}>
                <TextInput
                  type="number"
                  placeholder={formatMessage(messages.createClientCompanyMaxEasyEvaluationsInputPlaceHolder)}
                  value={client.maxEasyEvaluations}
                  onChange={e => {
                    setClient({
                      ...client,
                      maxEasyEvaluations: e.target.value,
                    })
                  }}
                  required
                  invalid={showErrors && errors.maxEasyEvaluations}
                  errors={errors.maxEasyEvaluations}
                />
              </Label>
            </div>
            <div className="flex flex-row items-center ">
              <Label className="mt-4 md:flex " name={formatMessage(messages.createClientCompanyNumberOfPersonsInputLabel)}>
                <TextInput
                  type="number"
                  placeholder={formatMessage(messages.createClientCompanyNumberOfPersonsInputPlaceHolder)}
                  value={client.numberofpersons}
                  onChange={e => {
                    setClient({
                      ...client,
                      numberofpersons: e.target.value,
                    })
                  }}
                  required
                  invalid={showErrors && errors.numberofpersons}
                  errors={errors.numberofpersons}
                />
              </Label>
            </div>
          </div>

          <Subtitle className="mt-6 mb-2">{formatMessage(messages.createClientContactPersonSubtitle)}</Subtitle>
          <div>
            {client.contactpersons.map((person, idx) => {
              return (
                <NewPerson
                  key={idx}
                  setPerson={person => {
                    const updatedListOfContactPersons = [...client.contactpersons]
                    updatedListOfContactPersons[idx] = person
                    setClient({
                      ...client,
                      contactpersons: updatedListOfContactPersons,
                    })
                  }}
                  person={person}
                  errors={errors.contactpersons && errors.contactpersons[idx]}
                  showErrors={showErrors}
                />
              )
            })}
          </div>
          <div className="flex flex-row items-center justify-start cursor-pointer mt-2" onClick={addNewPersonForm}>
            <p className="text-gray-700 text-sm font-medium">{formatMessage(messages.createClientAddMoreUsersButton)}</p>
          </div>
          <div className="w-full flex justify-start items-center">
            <Button primary onClick={handleSubmit} loading={CLoading || PLoading}>
              {formatMessage(messages.createClientSubmitButton)}
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NewClient
